import {
  ReactNode,
  useState,
  useEffect,
  useCallback,
  Fragment,
  useRef,
  createContext,
} from "react";
import { Icon, Box, Flex, SystemProps, css, Text } from "@storyofams/react-ui";
import axios from "axios";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { defineMessages } from "react-intl";
import styled from "styled-components";

import { useShopify } from "~context";
import { showToast } from "~lib";
import { shopifySdk } from "~lib/shopify/client";
import { NavigationItem, FooterItem, CartItem } from "~lib/storyblok/sdk";

import { Button } from "../Button";
import { Search } from "../Icons";
import { Navigation } from "../Navigation";
import { Menu } from "../Navigation/components/Menu";
import { NavigationCTABanner } from "../Navigation/components/NavigationCTABanner";
import { NavigationMegaLink } from "../Navigation/components/NavigationMegaLink";
import { NavigationPrimary } from "../Navigation/components/NavigationPrimary";
import { NavigationUSPBanner } from "../Navigation/components/NavigationUSPBanner";
import { NewsletterBanner } from "../NewsletterBanner";
import { Popups } from "../Popups";
import { SubscriptionPopup } from "../Popups/SubscriptionPopup";
import { Breadcrumbs } from "./Breadcrumbs";
import { Footer } from "./Footer";
import { SearchResults } from "./SearchResults";

const colorGradients = {
  Draagoliën: {
    gradient: "rgba(234, 200, 181, 0.7) 0%, rgba(234, 200, 181, 0) 100vh",
    color: "#EAC8B5",
  },
  "Etherische olie": {
    gradient: "rgba(230, 225, 215, 0.9) 0%, rgba(230, 225, 215, 0) 100vh",
    color: "#E6E1D7",
  },
  Hydrosolen: {
    gradient: "rgba(219, 228, 219, 0.5) 0%, rgba(219, 228, 219, 0) 100vh",
    color: "#DBE4DB",
  },
  "(Basis) Zepen": {
    gradient: "rgba(238, 210, 203, 0.6) 0%, rgba(238, 210, 203, 0) 100vh",
    color: "#EED2CB",
  },
  "Klei & Poeders": {
    gradient: "rgba(222, 206, 187, 0.7) 0%, rgba(222, 206, 187, 0) 100vh",
    color: "#DECEBB",
  },
  "Kruiden & Plantpoeders": {
    gradient: "rgba(216, 220, 192, 0.5) 0%, rgba(216, 220, 192, 0) 100vh",
    color: "#D8DCC0",
  },
  "Boters & Wassen": {
    gradient: "rgba(237, 215, 183, 0.4) 0%, rgba(237, 215, 183, 0) 100vh",
    color: "#EDD7B7",
  },
  Hulpstoffen: {
    gradient: "rgba(218, 192, 175, 0.7) 0%, rgba(218, 192, 175, 0) 100vh",
    color: "#D8CACA",
  },
  overall: {
    gradient: "rgba(241, 227, 213, 0.6) 0%, rgba(216, 202, 202, 0) 100vh",
    color: "#E6D8CF",
  },
};

const Wrapper = styled(Flex)<{ gradientType?: string }>`
  ${(p) => {
    if (p.gradientType && p.gradientType.includes("linear"))
      return `background: ${p.gradientType}`;
    if (p.gradientType && p.gradientType.includes("background"))
      return `${p.gradientType}`;
    return `background: linear-gradient(180deg, ${
      colorGradients[p.gradientType]
        ? colorGradients[p.gradientType].gradient
        : colorGradients.overall.gradient
    })`;
  }};
`;

const messages = defineMessages({
  searchPlaceholder: "Search for product, inspiration or answer",
});

export const ColorProvider = createContext(colorGradients[0]);
interface LayoutProps extends SystemProps {
  children: ReactNode;
  navigation: NavigationItem;
  footer: FooterItem;
  cart: CartItem;
  gradientType?: string;
  productBg?: string;
  footerColor?: string;
}

export const Layout = ({
  children,
  footer,
  navigation,
  cart,
  gradientType,
  footerColor,
  productBg,
  ...props
}: LayoutProps) => {
  const router = useRouter();
  const posthog = usePostHog();
  const ref = useRef(null);
  const { createEmptyCart, cart: shopifyCart, cartIsLoading } = useShopify();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  const [IsSearching, setIsSearching] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const onScroll = () => {
    if (window.scrollY > 64) {
      if (!isScrolled) {
        setScrolled(true);
      }
    } else {
      setScrolled(false);
    }
  };

  const color = colorGradients[gradientType]
    ? colorGradients[gradientType]
    : colorGradients.overall;
  const handler = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "k") {
      setIsSearching((search) => !search);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    document.addEventListener<"keydown">("keydown", handler, false);

    return () => {
      window.removeEventListener("scroll", onScroll);
      document.removeEventListener<"keydown">("keydown", handler, false);
    };
  }, [onScroll]);
  const setDiscountCode = async (code: string) => {
    try {
      const { data } = await axios.get(
        `/api/shopify/order/checkCode?code=${code}`
      );
      if (data?.message && data.message.includes("404")) {
        showToast("Code niet gevonden!", "error");
        return;
      }
      if (!shopifyCart?.id) await createEmptyCart(code);
      else
        await shopifySdk.cartDiscountCodesUpdate({
          cartId: String(shopifyCart.id),
          discountCodes: [code],
        });
      showToast("De code wordt toegepast bij het afrekenen!", "success");
    } catch {
      showToast("Code not applied", "error");
    }
  };
  useEffect(() => {
    if (router.query?.snowball && !cartIsLoading && !ref.current) {
      ref.current = true;
      setDiscountCode(String(router.query.snowball));
    }
  }, [router.isReady, cartIsLoading]);
  const close = useCallback(() => setIsSearching(false), []);
  const open = useCallback(() => setIsSearching(true), []);
  const productBackground = productBg ? productBg.split(" ")[1] : "";
  return (
    <>
      <Wrapper
        flexDirection="column"
        width="100%"
        gradientType={productBg || gradientType}
      >
        {navigation?.content?.cta?.content?.[0]?.content && (
          <Box
            cursor={navigation?.content?.is_show_popup_on_click && "pointer"}
            onClick={() => {
              if (navigation?.content?.is_show_popup_on_click) {
                setOpen(true);
                setTimeout(() => setOpen(false), 1000);
              }
            }}
          >
            <NavigationCTABanner
              text={navigation?.content?.cta}
              bg={navigation?.content?.cta_color?.color}
              color={navigation?.content?.cta_text_color?.color}
            />
          </Box>
        )}
        <Box
          borderBottom={"1px solid grey200"}
          display={["none", "flex", "flex"]}
        >
          {navigation?.content?.usp?.[0]?.text && (
            <NavigationUSPBanner usp={navigation?.content?.usp} />
          )}
        </Box>

        <Navigation>
          <Box>
            <NavigationPrimary
              isScrolled={isScrolled}
              open={open}
              mobileMenuOpen={mobileMenuOpen}
              setMobileMenuOpen={setMobileMenuOpen}
              cartNote={cart?.content?.payment_note}
              paymentOptions={footer?.content?.payment_options}
              scrolledStyles={{
                backgroundColor: isScrolled && "rgba(255, 255, 255, 0.8)",
                backdropFilter: isScrolled && "blur(8px)",
              }}
            >
              <Flex
                flex={1}
                justifyContent={[
                  "flex-start",
                  "flex-start",
                  "center",
                  "center",
                ]}
                alignItems={["flex-start", "flex-start", "center"]}
                width={"100%"}
              >
                <Box
                  display={["block", "block", "none", "none"]}
                  width={"100%"}
                >
                  <NavigationMegaLink
                    mobileMenuOpen={mobileMenuOpen}
                    setMobileMenuOpen={setMobileMenuOpen}
                    key={navigation?.content?.link_list[0]?._uid}
                    megaData={navigation?.content}
                  />
                </Box>

                {router.pathname !== "/search" && (
                  <Box>
                    <Box
                      display={["none", "none", !IsSearching && "block"]}
                      minWidth={["100%", 0, 0, "400px"]}
                      css={css({
                        input: { minHeight: "40px", py: "9px" },
                        svg: { width: "16px" },
                      })}
                    >
                      <Button
                        onClick={() => {
                          setIsSearching(true);
                          // posthog.capture("Search clicked");
                        }}
                        variant="unstyled"
                        borderStyle="solid"
                        borderWidth="1px"
                        borderColor="#E2DFDF"
                        height={"42px"}
                        minHeight={"42px"}
                        bg="white"
                        py="9px"
                        fontSize={2}
                        borderRadius="sm"
                        justifyContent="space-between"
                        alignItems="center"
                        pl={2}
                        pr={5}
                        color="#767473"
                        cursor="text"
                      >
                        {/* <FormattedMessage {...messages.searchPlaceholder} /> */}
                        <Text opacity={"0.6"}>
                          Zoek naar product, inspiratie of antwoord
                        </Text>

                        <Icon
                          display="flex"
                          alignItems="center"
                          position="absolute"
                          right={1.5}
                          top={0}
                          bottom={0}
                          color="grey800"
                          fontSize={"16px"}
                          icon={Search}
                        />
                      </Button>
                    </Box>
                  </Box>
                )}
              </Flex>
            </NavigationPrimary>
          </Box>
          <Box
            mx={"auto" as any}
            maxWidth="1332px"
            alignItems="center"
            pr={2}
            pl={2}
          >
            <Menu navigation={navigation} />
          </Box>
        </Navigation>
        {router.pathname !== "/search" && (
          <Box
            display={["block", "block", "none"]}
            opacity={isScrolled ? 0 : 1}
            top={7}
            zIndex={isScrolled ? -1000 : 110}
            position={"sticky"}
            minWidth={["100%", 0, 0, "400px"]}
            css={css({
              input: { minHeight: "40px", py: "9px" },
              svg: { width: "16px" },
            })}
            mx={"auto" as any}
            pr={[2, 2, 2, 10]}
            pl={[2, 2, 2, 10]}
            mb={["10px", "10px", 0]}
          >
            <Button
              width={"100%"}
              onClick={() => {
                if (isScrolled) return null;
                setIsSearching(true);
                // posthog.capture("Search clicked");
              }}
              variant="unstyled"
              borderStyle="solid"
              borderWidth="1px"
              borderColor="#E2DFDF"
              bg="white"
              py="10.5px"
              fontSize={"14px"}
              borderRadius="6px"
              justifyContent="space-between"
              alignItems="center"
              pl={2}
              pr={5}
              color="#767473"
              cursor="text"
            >
              {/* <FormattedMessage {...messages.searchPlaceholder} /> */}
              <Text opacity={"0.6"}>
                Zoek naar product, inspiratie of antwoord
              </Text>
              <Icon
                display="flex"
                alignItems="center"
                position="absolute"
                right={1.5}
                top={0}
                bottom={0}
                color="grey800"
                fontSize={"16px"}
                icon={Search}
              />
            </Button>
          </Box>
        )}
        <Box
          pr={1}
          pl={2}
          mb="12px"
          display={["flex", "none"]}
          alignItems={"center"}
        >
          {navigation?.content?.usp.map((item, i) => (
            <Box
              key={item.text}
              width={"33%"}
              fontWeight={400}
              fontSize={"10px"}
              lineHeight={"10.8px"}
              textAlign={"center"}
              pr={i !== navigation?.content?.usp.length - 1 && "5px"}
              pl={i !== 0 && "5px"}
            >
              <Text>{item.text}</Text>
            </Box>
          ))}
        </Box>
        <Box maxWidth="1300px" px={[2, 0]} mx="auto" width="100%">
          <Breadcrumbs gradientType={gradientType} />
        </Box>
        <Box
          as="main"
          flexDirection="column"
          flex="1"
          width="100%"
          pb={[5, 7]}
          {...props}
        >
          <ColorProvider.Provider value={{ ...color, productBackground }}>
            {children}
          </ColorProvider.Provider>
        </Box>

        <NewsletterBanner
          title={footer?.content?.newsletter_title}
          description={footer?.content?.newsletter_description}
          message={footer?.content?.newsletter_message}
          placeholder={footer?.content?.newsletter_placeholder}
          button={footer?.content?.newsletter_button}
          color={footerColor || footer?.content?.newsletter_color.color}
        />

        <Footer content={footer?.content} />
      </Wrapper>
      {IsSearching && <SearchResults isSrcolled={isScrolled} close={close} />}
      <Popups
        days={navigation?.content?.popup_days_delay}
        seconds={navigation?.content?.popup_seconds_delay}
        popup={navigation?.content?.popup}
      />
      {navigation?.content?.isGreenFridayPopupEnabled && (
        <SubscriptionPopup open={isOpen} />
      )}
    </>
  );
};
