import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Text } from "@storyofams/react-ui";
import axios from "axios";
import Image from "next/image";
import Link from "next/link";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { showToast } from "../../../lib";
import { Button } from "../Button";
import { PhoneInput } from "../Forms";
import { Input } from "../Input";
import { Modal } from "../Modal";

const schema = Yup.object().shape({
  email: Yup.string().email().required(),
  phone_number: Yup.string()
    .nullable()
    .transform((val) => (!val ? null : val)),
});
export const SubscriptionPopup = ({ isDisabled = false, open = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isNoPhone, setIsNoPhone] = useState(false);
  const popupHandle = () => {
    setIsOpen(true);
    document.cookie = "subscribe=1";
  };
  useEffect(() => {
    const decodedCookies = decodeURIComponent(document.cookie).split(";");
    const { subscribe: isReturning } = Object.assign(
      {},
      ...decodedCookies.map((cookie) => {
        const [key, value] = cookie.split("=");
        return { [key.trim()]: value };
      })
    );
    let timerId;
    if (!isReturning) {
      timerId = setTimeout(popupHandle, 1000);
    }
    return () => {
      clearInterval(timerId);
    };
  }, []);
  useEffect(() => {
    if (open) setIsOpen(true);
  }, [open]);
  const [isPhoneInput, setIsPhoneInput] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm<{ email: string; phone_number: string }>({
    resolver: yupResolver(schema) as any,
  });
  const onSubmit = async (input) => {
    try {
      if (!isPhoneInput) setIsPhoneInput(true);
      else if (isNoPhone) {
        const { data } = await axios.post("/api/klaviyo/subscribe", {
          ...input,
          isModal: true,
          phone_number: "",
        });
        if (data?.name === "Error")
          showToast("Please enter a valid phone number", "error");
        else setIsDone(true);
      } else {
        if (!input.phone_number)
          showToast("Please enter a valid phone number", "error");
        else {
          const { data } = await axios.post("/api/klaviyo/subscribe", {
            ...input,
            isModal: true,
          });
          if (data?.name === "Error")
            showToast("Please enter a valid phone number", "error");
          else setIsDone(true);
        }
      }
    } catch (err) {
      if (err.message.includes("409"))
        showToast("Gebruiker bestaat al", "error");
      else showToast(err?.message, "error");
    } finally {
      setIsNoPhone(false);
    }
  };
  if (isDone)
    return (
      <Modal
        overflow="visible"
        isOpen={isOpen}
        isBlured
        close={() => {
          setIsOpen(false);
        }}
      >
        <Box p={"24px"} bg="#F7EFE7" position={"relative"} borderRadius={"8px"}>
          <Box>
            <Box
              mx="auto"
              top={-8}
              left={0}
              right={0}
              width={130}
              height={130}
              position={"absolute"}
            >
              <Image
                layout="fill"
                src={
                  "https://a.storyblok.com/f/125270/451x451/20429f6a4e/ty.png"
                }
              />
            </Box>
            <Text
              fontFamily={"DINPro"}
              fontWeight={700}
              textAlign={"center"}
              fontSize={["24px", "36px"]}
              lineHeight={["28.8px", "43.2px"]}
              mb={"16px"}
              mt={"32px"}
            >
              Gelukt!
            </Text>
            <Text
              textAlign={"center"}
              lineHeight={"25.2px"}
              color={"#1D1D1B"}
              mb={"32px"}
            >
              Je krijgt van ons bericht wanneer we live gaan.
            </Text>
            <Button
              mt="12px"
              width={"100%"}
              variant="primary"
              height={"57px"}
              onClick={() => setIsOpen(false)}
            >
              Sluiten
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  return (
    <Modal
      isDisabled={isDisabled}
      overflow="visible"
      isBlured
      isOpen={isOpen}
      close={() => {
        setIsOpen(false);
        reset();
      }}
    >
      <Box
        p={"24px"}
        bg="#F7EFE7"
        position={"relative"}
        borderRadius={"8px"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {isPhoneInput ? (
          <Box>
            <Box
              mx="auto"
              top={-8}
              left={0}
              right={0}
              width={130}
              height={130}
              position={"absolute"}
            >
              <Image
                layout="fill"
                src={
                  "https://a.storyblok.com/f/125270/451x451/5618e6a4ce/phone.png"
                }
              />
            </Box>
            <Text
              fontFamily={"DINPro"}
              fontWeight={700}
              textAlign={"center"}
              fontSize={["24px", "36px"]}
              lineHeight={["28.8px", "43.2px"]}
              mb={"16px"}
              mt={"40px"}
            >
              Whatsapp updates
            </Text>
            <Text
              fontSize={"18px"}
              lineHeight={"25.2px"}
              color={"#1D1D1B"}
              textAlign={"center"}
              mb={"32px"}
            >
              Ontvang de toegangslink via Whatsapp.
            </Text>
            <Controller
              name="phone_number"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  defaultCountry={"NL"}
                  //@ts-ignore
                  international
                  withCountryCallingCode
                  error={
                    errors?.phone_number?.message
                      ? String(errors?.phone_number?.message)
                      : field.value && field.value[1] === "0"
                      ? "Voer uw telefoonnummer in gevolgd door de landcode"
                      : null
                  }
                  {...field}
                />
              )}
            />
            <Text
              mt={"12px"}
              color="#1D1D1BCC"
              fontSize={"12px"}
              lineHeight={"16.8px"}
            >
              Door akkoord te gaan, geef je toestemming om promotionele
              berichten te ontvangen via WhatsApp en SMS. Bekijk ons
              privacybeleid voor meer informatie. Je kunt je op elk moment
              afmelden
              <Link href="/privacy-beleid ">
                <Text
                  cursor={"pointer"}
                  textDecoration={"underline"}
                  display={"inline"}
                >
                  Privacy Policy & Terms.
                </Text>
              </Link>
            </Text>
            <Button
              mt="12px"
              width={"100%"}
              variant="primary"
              height={"57px"}
              type="submit"
              isLoading={isSubmitting}
            >
              Verstuur
            </Button>
            <Button
              mt="12px"
              width={"100%"}
              onClick={() => {
                setIsNoPhone(true);
                handleSubmit(onSubmit);
              }}
              height={"57px"}
              variant="secondary"
              isLoading={isSubmitting}
            >
              Nee, bedankt
            </Button>
          </Box>
        ) : (
          <Box>
            <Box
              mx="auto"
              top={-8}
              left={0}
              right={0}
              width={130}
              height={130}
              position={"absolute"}
            >
              <Image
                layout="fill"
                src={
                  "https://a.storyblok.com/f/125270/301x300/03328f9a0b/group-26.png"
                }
              />
            </Box>
            <Box
              width={"100%"}
              position={"relative"}
              height={["130px", "260px"]}
              borderRadius={"8px"}
              mt="40px"
              overflow={"hidden"}
              mb="24px"
            >
              <Image
                layout="fill"
                quality={20}
                objectFit="cover"
                src="https://a.storyblok.com/f/125270/2731x4096/4f0b6f1dc0/home_banner_bars_dev.jpeg"
              />
            </Box>
            <Box textAlign={"center"}>
              <Text
                fontFamily={"DINPro"}
                fontWeight={700}
                fontSize={["24px", "36px"]}
                lineHeight={["28.8px", "43.2px"]}
                mb={"16px"}
              >
                {"Green Friday"}
              </Text>
              <Text
                fontSize={"18px"}
                lineHeight={"25.2px"}
                mb={"16px"}
                fontStyle={"italic"}
              >
                Krijg één dag eerder toegang tot de sale.
              </Text>
              <Text fontSize={"18px"} lineHeight={"25.2px"} mb={"24px"}>
                Korting het hoogst op de eerste dag, en wordt elke dag minder,
                dus wees er snel bij!
              </Text>
              <Input
                width={"100%"}
                mb="12px"
                placeholder="Jouw e-mailadres"
                required
                height={"49px"}
                autoFocus
                type="email"
                {...register("email")}
                error={errors?.email?.message}
              />
              <Button
                width={"100%"}
                height={"48px"}
                type="submit"
                isLoading={isSubmitting}
              >
                Meld je aan
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
